<script lang="ts" setup>
const props = defineProps<{
    primary?: boolean
    secondary?: boolean
    flat?: boolean
    small?: boolean
    medium?: boolean
    large?: boolean
    ghost?: boolean
    disabled?: boolean
}>()
const emit = defineEmits(['click'])
const noSizeInformed = computed((): boolean => !(props.small || props.medium || props.large))
const noTypeInformed = computed((): boolean => !(props.primary || props.secondary))
const isBasic = computed((): boolean => !(props.flat || props.ghost))
const onClick = () => emit('click')
</script>

<template>
    <div
        font-bold
        rounded-2
        flex items-center
        transition
        select-none justify-center
        :class="[
            // size styles
            {
                [`
                px-6 py-2.5 text-3.5
            `]:
                medium || noSizeInformed,
            },
            {
                [`
                px-8 py-3 text-4
            `]:
                large,
            },
            {
                [`
                px-4 py-1.5 text-3.5
            `]:
                small,
            },
            // background styles
            {
                [`
                bg-primary ${!disabled && 'hover:bg-primary-300'}
                shadow-md shadow-#50675b29 dark:shadow-#0f110f24
                text-white
            `]:
                isBasic && (primary || noTypeInformed),
            },
            {
                [`
                bg-secondary ${!disabled && 'hover:bg-secondary-300'}
                shadow-lg shadow-secondary-#50675b29 dark:shadow-#0f110f24
                text-white
            `]:
                isBasic && secondary,
            },
            {
                [`
                bg-background-100 dark:bg-backgroundlight ${!disabled && 'hover:bg-primary-100'}
                b-2 b-primary-100 ${!disabled && 'hover:b-2 hover:b-primary-300'}
                text-primary-500 dark:text-textcolor-100
            `]:
                flat && (primary || noTypeInformed),
            },
            {
                [`
                bg-secondary-100 ${!disabled && 'hover:bg-secondary-100'}
                b-2 b-secondary-300 ${!disabled && 'hover:b-secondary-500'}
                text-secondary-700
            `]:
                flat && (secondary),
            },
            {
                [`
                bg-transparent ${!disabled && 'hover:bg-primary-300'}
                b-2 b-primary-300 ${!disabled && 'hover:b-primary-300'}
                text-primary-500 ${!disabled && 'hover:text-white'}
            `]:
                ghost && (primary || noTypeInformed),
            },
            {
                [`
                bg-transparent ${!disabled && 'hover:bg-secondary-500'}
                b-2 b-secondary-300 ${!disabled && 'hover:b-secondary-300'}
                text-secondary-700 ${!disabled && 'hover:text-white'}
            `]:
                ghost && (secondary),
            },
            { 'op50 cursor-not-allowed': disabled },
        ]"
        @click="() => !disabled && onClick()"
    >
        <div v-if="$slots.icon" text="4.5" mr-1>
            <slot name="icon" />
        </div>
        <slot />
    </div>
</template>
